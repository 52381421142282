import { useQuery, useQueryClient } from '@tanstack/react-query'

import { LOCATION } from '../../../../contact/hooks'
import { LocationDetailsDto } from '../../../../types'
import { getLocationDetails } from '../../../services/locationService/getDetails'

const DETAILS = 'DETAILS'

export function useLocationDetails(locationId?: number) {
  return useQuery(
    [LOCATION, DETAILS, locationId],
    () => {
      if (!locationId) {
        return undefined
      }
      return getLocationDetails(locationId)
    },
    {
      enabled: !!locationId,
    },
  )
}

export function useLocationsDetails(ids: number[]) {
  const client = useQueryClient()
  return useQuery(
    [LOCATION, DETAILS, ids],
    async () => {
      const details = await Promise.all(ids.map(getLocationDetails))
      return ids.reduce<{ [id: number]: LocationDetailsDto }>(
        (locationById, id, index) => {
          // eslint-disable-next-line no-param-reassign
          locationById[id] = details[index]
          return locationById
        },
        {},
      )
    },
    {
      enabled: ids.length > 0,
      onSuccess: (details) => {
        if (details) {
          Object.keys(details).forEach((id) => {
            client.setQueryData([LOCATION, DETAILS, +id], details[+id])
          })
        }
      },
      useErrorBoundary: true,
    },
  )
}
