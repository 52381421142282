import React from 'react'

import { Popover, SearchInput } from '@kenect-ut/kenect-ui-kit'
import classNames from 'classnames'
import orderBy from 'lodash/orderBy'

import type { ReplyMessageTemplate } from '../../types'
import { stringComparator } from './ActionItem.utils'
import styles from './ActionPopOver.module.scss'

type Props = {
  templates: ReplyMessageTemplate[]
  locationId?: number
  searchTerm?: string
  popoverRef: React.MutableRefObject<unknown>
  popoverType: string
  popoverOpen: boolean
  searchPlaceholder: string
  handleTogglePopover: () => void
  handleOptionSelection: (template: ReplyMessageTemplate) => void
  handleSearchInputChange: (arg: string) => void
}
const resourceTypeId = 'resourceUrls'

function ActionPopOver({
  templates,
  locationId,
  searchTerm,
  popoverRef,
  popoverType,
  popoverOpen,
  searchPlaceholder,
  handleTogglePopover,
  handleOptionSelection,
  handleSearchInputChange,
}: Props) {
  const locationTemplateOptions = locationId
    ? templates?.filter((tOpt) => tOpt.locationId === locationId)
    : templates
  const hasLocationOptions = Boolean(locationTemplateOptions?.length)

  const searchedAndOrderedOptions = locationTemplateOptions?.length
    ? orderBy(
        locationTemplateOptions.filter((opt) =>
          stringComparator([opt.title, opt.template], searchTerm),
        ),
        'title',
        'asc',
      )
    : []

  const NoOptionsFound = (
    <div className={styles.messageTemplatesContainer}>
      <p
        className={classNames(styles.templateHeader, styles.noLocationOptions)}
      >
        {popoverType !== resourceTypeId
          ? 'There are no Quick Replies available.'
          : 'There are no Resource Links available.'}
      </p>
    </div>
  )
  const LocationSearchHeader = (
    <div className={classNames(styles.templateHeader, styles.mb2)}>
      <SearchInput
        onChange={handleSearchInputChange}
        placeholder={searchPlaceholder}
        value={searchTerm}
      />
    </div>
  )

  return (
    <Popover
      isOpen={popoverOpen}
      onClose={handleTogglePopover}
      targetRef={popoverRef.current as Element}
      placement="top-start"
      content={
        <>
          {hasLocationOptions && (
            <div
              className={styles.messageTemplatesContainer}
              data-testid="compositionArea-templates"
            >
              {locationTemplateOptions?.length > 7 && LocationSearchHeader}
              <ul className={styles.optionsContainer}>
                {searchedAndOrderedOptions.map((template) => (
                  <li key={template.id}>
                    <button
                      type="button"
                      data-tracking-id={`actionItem-${popoverType}-Option`}
                      data-testid={`actionItem-${popoverType}-Option`}
                      className={styles.templateOption}
                      onClick={() => handleOptionSelection(template)}
                    >
                      {template.title}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {!hasLocationOptions && NoOptionsFound}
        </>
      }
    />
  )
}

export default ActionPopOver
