import React, { useEffect } from 'react'

import './common/utils/firebase'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Route, Routes, Navigate } from 'react-router-dom'

import { AuthProvider } from './auth/AuthProvider'
import { LoginPage } from './auth/LoginContainer'
import { RequireAuth } from './auth/RequireAuth'
import { clearAppBadge } from './common/badgeUtil'
import ErrorBoundary from './common/components/ErrorBoundary'
import { Dashboard } from './dashboard/Dashboard'
import '@kenect-ut/kenect-ui-kit/lib/styles.css'
import { UpdateAppAlert } from './pwa/UpdateAppAlert'
import { useRegisterServiceWorker } from './pwa/useServiceWorker'

const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: 5 * 60 * 1000 } },
})

function onLogout() {
  queryClient.removeQueries()
  localStorage.clear()
}

function useClearBadge() {
  useEffect(() => clearAppBadge, [])
}

function App() {
  useClearBadge()
  const { requiresUpdate, updateApp, updating } = useRegisterServiceWorker()
  return (
    <ErrorBoundary>
      <AuthProvider onLogout={onLogout}>
        <QueryClientProvider client={queryClient}>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route element={<RequireAuth />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route
                path="/dashboard/lead/:conversationId"
                element={<Dashboard />}
              />
            </Route>
            <Route path="*" element={<Navigate replace to="/dashboard" />} />
          </Routes>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </AuthProvider>
      {requiresUpdate && (
        <UpdateAppAlert updating={updating} onClick={updateApp} />
      )}
    </ErrorBoundary>
  )
}

export default App
