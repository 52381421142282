import { EmailValidator } from 'commons-validator-js'
import * as yup from 'yup'

import '../common/utils/phoneValidator'
import type { Contact, PhoneNumber } from '../types'

export interface ContactFormData {
  id?: number
  firstName?: string
  lastName?: string
  phone?: string
  primaryEmail?: string
  note?: string
}

export const contactFormSchema = yup.object().shape({
  firstName: yup.string(),
  lastName: yup.string(),
  phone: yup.string().phone('US', false, 'Please enter valid phone number'),
  primaryEmail: yup
    .string()
    .test('email', 'Please enter a valid email', (value) => {
      // This is a javascript port of the Apache Commons Validator that is used on the backend for email validation
      // https://github.com/wix-incubator/commons-validator-js
      return value ? new EmailValidator().isValid(value) : true
    }),
})

export interface IUserPhoneDto {
  id: number
  number: string
  primary: boolean
  smsCapable: boolean
  type: string
  contactId?: number
}
export type UpsertContactDto = {
  firstName?: string
  lastName?: string
  locationId: number
  organizationId?: number
  phone: string
  phoneId: number
  homePhoneId?: number
  phoneNumbers: IUserPhoneDto[]
  note?: string
}

interface IBuildContactForUpsertArgs {
  contact: Partial<Contact> & { phone: string }
  options: { locationId: number; organizationId?: number; contactId?: number }
}

export const buildContactForUpsert = ({
  contact,
  options: { locationId, organizationId, contactId },
}: IBuildContactForUpsertArgs): UpsertContactDto => {
  const updatedPhoneNumbers: IUserPhoneDto[] = [
    {
      id: 1,
      number: contact.phone,
      primary: true,
      smsCapable: true,
      type: 'CELL',
    },
  ]
  if (contactId) {
    updatedPhoneNumbers[0].contactId = contactId
  }

  return {
    ...contact,
    locationId,
    organizationId,
    phoneId: 1,
    phoneNumbers: updatedPhoneNumbers as PhoneNumber[],
  }
}
