import React, { useMemo } from 'react'

import classNames from 'classnames'

import { LoadingView } from '../common/components/LoadingView'
import TitleSmall from '../common/components/typography/TitleSmall'
import { useLocationDetails } from '../common/hooks/services/locationService/useLocationDetails'
import { useConversation } from '../common/hooks/services/managedConversationService/useConversation'
import useCurrentConversationId from '../common/hooks/useCurrentConversationId'
import { LocationDetailsDto } from '../types'
import styles from './Dealer.module.scss'
import { IBusinessHour } from './dealer.utils'
import { DealerAddressListItem } from './DealerAddressListItem'
import { DealerNotesToAgent } from './DealerNotesToAgent'
import { LocationBusinessHours } from './LocationBusinessHours'

const days = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
]

function fromLocationDetailsDtoToBusinessHours(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loc?: Record<string, any>,
): IBusinessHour[] {
  if (!loc) {
    return []
  }
  return days.reduce<IBusinessHour[]>((hours, day) => {
    const prefix = day.toLowerCase()
    if (loc[`${prefix}StartTime`] && loc[`${prefix}EndTime`]) {
      hours.push({
        dayOfWeek: day,
        startTime: loc[`${prefix}StartTime`],
        endTime: loc[`${prefix}EndTime`],
      })
    }
    return hours
  }, [])
}

function useLocationHours(locationDetails?: LocationDetailsDto) {
  return useMemo(
    () => fromLocationDetailsDtoToBusinessHours(locationDetails),
    [locationDetails],
  )
}

export function DealerContainer() {
  const conversationId = useCurrentConversationId()
  const { data: conversation } = useConversation(conversationId)
  const {
    data: locationDetails,
    isLoading,
    error,
  } = useLocationDetails(conversation?.locationId)
  const locationHours = useLocationHours(locationDetails)

  if (error) {
    throw error
  }

  if (isLoading) {
    return (
      <div className={styles.container}>
        <LoadingView />
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={classNames(styles.column, styles.leftColum)}>
          <TitleSmall className={styles.locationHeader}>
            {locationDetails?.organizationName}
          </TitleSmall>
          {locationDetails?.websiteUrl && (
            <a
              className={classNames(styles.externalLink, styles.locationUrl)}
              href={locationDetails?.websiteUrl}
              target="_blank"
              rel="noreferrer"
            >
              {locationDetails?.websiteUrl}
            </a>
          )}
          {locationDetails && (
            <ul className={styles.locationDetailsList}>
              <DealerAddressListItem location={locationDetails} />
            </ul>
          )}
          <LocationBusinessHours
            businessHours={locationHours}
            incomingLeadTimeZone={locationDetails?.timezone}
          />
        </div>
        <div className={classNames(styles.column, styles.rightColumn)}>
          {locationDetails?.inventoryUrl && (
            <a
              className={classNames(
                styles.externalLink,
                styles.inventoryButton,
              )}
              href={locationDetails?.inventoryUrl}
              target="_blank"
              rel="noreferrer"
            >
              Vehicle Inventory
            </a>
          )}
          {locationDetails?.notes && (
            <DealerNotesToAgent notes={locationDetails.notes} />
          )}
        </div>
      </div>
    </div>
  )
}
